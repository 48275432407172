import { useRef, useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'

import SuccessAlert from './SuccessAlert'

let alertTimer

export default function Example({ setIsLoggedIn }) {
  const [isSuccessAlert, setIsSuccessAlert] = useState(false)

  const passwordRef = useRef()
  return (
    <>
      <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full space-y-8'>
          <div>
            <img
              className='mx-auto h-12 w-auto'
              src='bank-symbol-black.svg'
              alt='Workflow'
            />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Inicia sesión en Caja de Ahorro
            </h2>
            <p className='mt-2 text-center text-sm text-gray-600'>
              App{' '}
              <span className='font-medium text-indigo-600 hover:text-indigo-500'>
                Pwa
              </span>
            </p>
          </div>
          <form className='mt-8 space-y-6' action='#' method='POST'>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='rounded-md shadow-sm -space-y-px'>
              <div>
                <label htmlFor='email-address' className='sr-only'>
                  Cédula
                </label>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                  placeholder='Cédula'
                />
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  Contraseña
                </label>
                <input
                  ref={passwordRef}
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                  placeholder='Contraseña'
                />
              </div>
            </div>

            <div>
              <button
                onClick={() => {
                  if (passwordRef.current.value === 'admin') {
                    setIsLoggedIn(true)
                  } else {
                    setIsSuccessAlert(true)
                    if (alertTimer) clearTimeout(alertTimer)
                    alertTimer = setTimeout(() => {
                      setIsSuccessAlert(false)
                      alertTimer = null
                    }, 4000)
                  }
                }}
                type='button'
                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                  <LockClosedIcon
                    className='h-5 w-5 text-indigo-500 group-hover:text-indigo-400'
                    aria-hidden='true'
                  />
                </span>
                Ingresar
              </button>
            </div>
          </form>
        </div>
        <SuccessAlert
          message={'Cédula o contraseña incorrectas'}
          isSuccessAlert={isSuccessAlert}
          setIsSuccessAlert={setIsSuccessAlert}
          isErrorMessage={true}
        />
      </div>
    </>
  )
}
